<script>
  // https://github.com/pstanoev/simple-svelte-autocomplete/blob/master/src/SimpleAutocomplete.svelte
  //import AutoComplete from "simple-svelte-autocomplete";

  // https://awesomeopensource.com/project/rob-balfre/svelte-select
  import Select from "svelte-select";
  import Tailwindcss from "./Tailwindcss.svelte";

  export const name = "KETO";

  const API =
    document.location.hostname === "localhost"
      ? "http://localhost:8000"
      : "/api";

  console.log(`▪︎ SET API TO ${API}`);

  let selectedValue = undefined;
  let servingsCounter = {};

  const getOptionLabel = option => option.title;
  const getSelectionLabel = option => option.title;
  const optionIdentifier = "id";

  //   const fetchImage = (async () => {
  //     const response = await fetch("https://dog.ceo/api/breeds/image/random");
  //     return await response.json();
  //   })();

  let current = [];

  async function get_collection(e) {
    if (selectedValue) {
      console.log("∫ getcollection: ", selectedValue.id);
      const response = await fetch(`${API}/collection/${selectedValue.id}`);
      const data = await response.json();
      console.log("DATA: ", data);
      //console.log("DATA: ", typeof data);

      if (response.ok) {
        current = [...data];
        for (let e of current) {
          servingsCounter[e.recipeId] = e.servings;
        }
      } else {
        throw new Error(data);
      }
      //console.log("DATA: ", data);
    }
  }

  async function get_collections() {
    const response = await fetch(`${API}/collections`);
    const data = await response.json();
    // console.log("typeof: ", typeof data);
    // console.log("typeof: ", data);
    if (response.ok) {
      return data;
    } else {
      throw new Error(data);
    }
  }

  function showIngredient(ingredient, recipeId) {
    //console.log("SHOW I ", ingredient, recipeId);
    let amount = ingredient.amount * servingsCounter[recipeId];
    return amount;
    //return [amount, ingredient.unit, ingredient.name];
    //return `<td>${amount}</td><td>${ingredient.unit}</td><td>${ingredient.name}</td>`;
  }

  function setServingsMinus(id) {
    servingsCounter[id] = servingsCounter[id] - 1;
    current = current; // force redraw
  }

  function setServingsPlus(id) {
    servingsCounter[id] = servingsCounter[id] + 1;
    current = current; // force redraw
  }

  //const collections = get_collections();
</script>

<style>
  /* orange = rgb(230,80,30); 
	medium_orange = rgb(235,133,99)
	light_orange = rgb(240,170,146)
	ultralight_orange = rgb(252,218,204)
	white = rgb(252,252,252)

  */

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  button,
  button:focus {
    outline: none;
  }

  body {
    display: flex;
  }

  main {
    text-align: center;
    padding: 1em;
    max-width: 240px;
    margin: 0 auto;
  }

  h1 {
    color: rgb(230, 80, 30);
    background-color: #eee;
    text-transform: uppercase;
    font-size: 1.1em;
    font-weight: 800;
    border-bottom: 2px solid #999;
    border-top: 2px solid #999;
    margin-top: 1em;
    padding: 0.4em;
    font-family: "Roboto Condensed";
  }

  h2 {
    /*@apply bg-orange text-white;*/
    background-color: rgb(230, 80, 30);
    color: black;
    padding: 0.4em;
    font-weight: bold;
  }

  header {
    background-color: rgb(230, 80, 30);
    font-size: 1.4em;
    color: white;
    padding: 0.4em;
    font-weight: bold;
    letter-spacing: 0.1em;
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }

  table {
    padding: 2em;
  }

  table > tr {
    margin-top: 1em;
  }
  table.collection > tr:nth-child(odd) {
    background-color: rgb(252, 218, 204);
  }

  td {
    vertical-align: top;
  }

  table.collection > thead > tr > th,
  table.ingredients > thead > tr > th {
    text-align: left;
    font-weight: 200;
    font-family: "Roboto Condensed";
    font-size: 0.8em;
    color: #ccc;
    padding-top: 0.4em;
  }

  table.ingredients > tr {
    border: 1px solid lightgrey;
  }
  .note {
    color: gray;
  }

  .entries {
    padding-left: 0.4em;
  }
</style>

<Tailwindcss />
<header>Keto Services</header>
<div class="container mx-auto ">
  {#await get_collections()}
    <p>Loading</p>
  {:then items}
    <div class="dropdown">

      <!--select on:change={get_collection}>
        {#each items as opt}
          <option label={opt.title} value={opt.id}>{opt.title}</option>
        {/each}
        bla:
      </select-->

      <Select
        on:select={get_collection}
        bind:selectedValue
        {optionIdentifier}
        {getOptionLabel}
        {getSelectionLabel}
        {items} />
      <!--AutoComplete
        placeholder="Kies een collection"
        onChange={get_collection}
        {items}
        bind:selectedItem={selectedValue}
        labelFieldName="title" /-->
    </div>
  {/await}

  {#if selectedValue}
    <!-- current: {JSON.stringify(current)} -->
    <!-- {#each xitems as entry}
      <li>{entry}</li>
    {/each} -->
    <div class="flex mb-4">
      <div>
        <h1>Collection {selectedValue.title}</h1>
        <table
          class="collection .table-auto .border-collapse px-4"
          cellpadding="6px">
          <thead>
            <tr>
              <th>dag</th>
              <th>moment</th>
              <th>name</th>
              <th>recipeId</th>
              <th>servings</th>
            </tr>
          </thead>
          {#each current as entry}
            <tr>
              <td>{entry.dag}</td>
              <td style="text-transform:lowercase">{entry.moment}</td>
              <td>{entry.name}</td>
              <td>{entry.recipeId}</td>
              <td nowrap>
                <button
                  style="width: 1.6em"
                  class="bg-transparent text-orange-500 hover:bg-orange-200
                  text-white font-bold rounded"
                  on:click={setServingsMinus(entry.recipeId)}>
                  <i class="fas fa-minus" />
                </button>
                {servingsCounter[entry.recipeId]}
                <button
                  style="width: 1.6em"
                  class="bg-transparent text-orange-500 hover:bg-orange-200
                  text-white font-bold rounded"
                  on:click={setServingsPlus(entry.recipeId)}>
                  <i class="fas fa-plus" />
                </button>

              </td>
            </tr>
          {/each}
        </table>
      </div>
      <div>
        <h1>boodschappenlijst</h1>
        <div class="entries">
          <table class="ingredients">
            <thead>
              <tr>
                <th align="right">amount</th>
                <th align="left">unit</th>
                <th>name</th>
                <th>notes</th>
              </tr>
            </thead>
            {#each current as entry}
              {#each entry.ingredients as ingredient}
                <tr>
                  <td align="right">
                    {showIngredient(ingredient, entry.recipeId)}
                  </td>
                  <td align="left">{ingredient.unit}</td>
                  <td>{ingredient.name}</td>
                  <td>
                    <i class="note">{ingredient.notes}</i>
                  </td>
                </tr>
              {/each}
              {#if entry && entry.ingredients.length}
                <tr>
                  <td colspan="4">&nbsp;</td>
                </tr>
              {/if}
            {/each}
          </table>

        </div>
      </div>
    </div>
  {/if}
</div>
